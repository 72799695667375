<template>
  <v-container class="container-full-size">
    <v-card color="basil" style="margin-top: 0" flat>
      <v-data-table
        :headers="headers"
        :items="data"
        :no-data-text="str['notifications_empty']"
        :no-results-text="str['notifications_empty']"
        class="elevation-1"
        :loading="isLoading"
        :loading-text="str['processing']"
      >
        <template #item.date="{ item }">
          {{ item.date ? item.date : '-' }}
        </template>
        <template #item.status="{ item }">
          <span v-if="status[item.status]" :class="[status[item.status].type + '--text']">
            {{ str[status[item.status].label] ? str[status[item.status].label] : status[item.status].label }}
          </span>
          <span v-if="!status[item.status]">
            {{ str['n_a'] }}
          </span>
        </template>
        <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  name: 'ClientNotifications',
  props: {
    client: Object,
  },
  data() {
    return {
      str: window.strings,
      user: Utils.getUser(),
      isLoading: false,
      data: [],
      status: this.getStatusDict(),
      headers: [
        { text: window.strings['title'], value: 'title', align: 'center' },
        { text: window.strings['body'], value: 'body', align: 'center' },
        { text: window.strings['date'], value: 'date', align: 'center' },
      ],
    }
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.getData()
  },
  methods: {
    refresh: function () {
      this.getData()
    },
    getData: function () {
      const self = this
      this.isLoading = true
      Api.getAppNotifications(this.client.dbId, function (response) {
        self.isLoading = false
        if (response.success) {
          self.data = response.data
        } else {
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    getStatusDict: function () {
      const dict = {}
      const list = Utils.getNotificationsStatus()
      if (list && list.length) {
        list.forEach(function (item) {
          dict[item.value] = item
        })
      }
      return dict
    },
  },
}
</script>
